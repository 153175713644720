.update-alert {
  .alert-wrapper {
    background-image: linear-gradient(90deg, var(--ion-color-secondary-dark) 0%, var(--ion-color-secondary) 100%);
    border-radius: 1rem;
    .alert-message {
      font-size: 15px;
      font-weight: 700;
      letter-spacing: 3px;
      color: white;
      text-align: center;

      img {
        margin-bottom: 2rem;
      }
    }
    
    .alert-button-group{
      display: flex;
      justify-content: center;
    }
    .alert-button {
      color: var(--ion-color-tertiary-contrast);
      background-color: var(--ion-color-success-tint);
      border-radius: .5rem;
    }
   
  }
  
}

@media screen and (min-width: 0px) and (max-width: 768px) {
    ion-tab-bar { 
        display: flex; }  /* show it on small screens */
  }
  
  @media screen and (min-width: 769px)  {
    ion-tab-bar { 
       display: none; }   /* hide it elsewhere */
  }
