.loginPage{
    --background: #f8f8f8;
  
  }
  
  .ResetPaswwwordForm {
    margin: 7rem 20rem;
    border-radius: .5rem;
    box-shadow: 0rem 0rem .2rem -.1rem var(--ion-color-dark);
    background-color: #ffffff;
  
    ion-col:nth-child(3) ion-icon{
      margin-top: 1.8rem;
    }
  
    .alert.alert-danger{
      display: flex;
      flex-direction: row;
      line-height: 2rem;
      margin-top: 1rem;
      opacity: 0;
  
      ion-icon{
        margin-right: 1rem;
      }
    }
  }
  
  footer {
    margin-top: 0rem !important;
  }
  
  @media all and(max-width: 1200px) {
    .ResetPaswwwordForm {
      margin: 10rem 10rem;
    }
  }
  
  @media all and(max-width: 991px) {
    .ResetPaswwwordForm {
      margin: 5rem 5rem;
    }
  }
  
  @media all and(max-width: 767px) {
    .ResetPaswwwordForm {
      margin: 5rem 1rem;
    }
  }
  