ion-fab-button {
    visibility: hidden !important;
}

.grid-rec-slider{
    background-color: #f5f6f9;
    margin-top: 3rem;
    border-radius: 1.25rem;
    margin-bottom: 2rem;
}

.RecommandedTitle{
    position: relative;
    margin-left: 1rem;
    padding-top: 0rem;
  }


@media (max-width: 991.98px) {
    ion-fab-button {
        visibility: inherit !important;
    }   
    .RecommandedTitle{
        position: relative;
        margin-left: 6rem;
        margin-right: 4rem;
      }
}

@media (max-width:  480px) {
    .grid-rec-slider{
        background-color: #f5f6f9;
        margin-top: 3rem;
        border-radius: .5rem;
    }
    .RecommandedTitle{
        position: relative;
        margin-left: 1rem;
        margin-right: 1rem
      }
}



