.SendPostPage {
    .SendPostForm {
        ion-row {
            background: var(--ion-color-light);
            box-shadow: 0 0 1px -2px var(--ion-color-dark);
            width: 100%;
        }

        ion-item {
            --border-color: #dcdcdc;
            align-content: left;
        }


        .card {
            border: 1px solid #dcdcdc;
            border-radius: 6px;
        }

        .text-info {
            font-size: 15px;
            align-self: flex-end;
        }

        .card-title {
            padding: 0 .5rem;
        }

        .pass-input-container {
            display: flex;
            width: 100%;
            border-color: #dcdcdc;
            border-width: thin;
            border-style: solid;
            border-radius: 6px;
            margin-bottom: 4px;
            height: 34px;

            ion-input {
                border-width: 0;
                margin-bottom: 0;
                height: 100%;
            }

            ion-icon {
                font-size: 27px;
                color: var(--ion-color-medium);
                align-self: flex-end;
                padding: 0px 5px 2px 5px;
            }
        }

        ion-text {
            margin-bottom: .3rem;
            align-self: flex-end;
        }


        ion-label {
            margin: 0px 5px 9px 5px;
            padding-top: 5px;
            font-size: 20px;
            overflow: visible;
        }

        .label-container {
            display: flex;
            justify-content: space-between;
            width: 100%;
        }

        .label-container-city {
            display: flex;
            flex-direction: column;
            width: 100%;

        }

        .facebook-column {
            display: flex;
            justify-content: center;

            .btn-facebook {
                font-size: 16px;
                width: 70%;
                height: 100%;
                background-color: #023ef2;
                color: white;
                display: block;
                border-radius: 6px;
                line-height: 29px;
                margin: 2rem 0rem;

                ion-icon {
                    font-size: 23px;
                    padding-top: 2px;
                }
            }
        }

        .separator {
            display: flex;
            flex-basis: 100%;
            align-items: center;
            color: rgba(0, 0, 0, 0.315);
            margin: .5rem 1rem 0rem 1rem;
        }

        .separator:before,
        .separator:after {
            content: "";
            flex-grow: 1;
            background: rgba(0, 0, 0, 0.315);
            height: 1px;
            font-size: 0px;
            line-height: 0px;
            margin: 0px 8px;
        }

        ion-input {
            border-color: #dcdcdc;
            border-width: thin;
            border-style: solid;
            border-radius: 6px;
            margin-bottom: 4px;
            font-size: 13px;

            input {
                margin: 5px 5px;
                height: 1rem;
            }
        }

        .ion-radio-container {
            border-color: #dcdcdc;
            border-width: thin;
            border-style: solid;
            border-radius: 12px;
            margin: 0px 16px;

            ion-label {
                font-size: 12px;
                margin-top: 5px;
            }
        }

        ion-textarea {
            border-color: #dcdcdc;
            border-width: thin;
            border-style: solid;
            border-radius: 6px;
            margin-bottom: 4px;
            margin-top: 0px;

            textarea {
                margin: 5px 5px;
            }
        }

        ion-radio {
            //margin-bottom: 17px;
            margin: 0px 1px 0px 1px;
        }

        ion-select {
            border-color: #dcdcdc;
            border-width: thin;
            border-style: solid;
            border-radius: 6px;
            margin-bottom: 4px;
        }

        .btn {
            border-radius: 6px;
        }


        @media all and(max-width: 767px) {
            .send-button {
                display: none;
            }
        }
    }

    @media all and(min-width: 768px) {
        .end-send-button {
            display: none;
        }
    }
}

.alert.alert-danger {
    display: flex;
    flex-direction: row;
    line-height: 2rem;
    margin-top: 1rem;
    align-items: center;
    border-radius: 1rem;

    ion-icon {
        width: 4rem;
        height: 2rem;
        padding-right: 10px;
        padding-left: 10px;
    }
}

.display {
    display: none;
}

@media all and(max-width: 767px) {
    .mbm-100 {
        margin-bottom: 100px !important;
        padding-right: 0px !important;
        padding-left: 0px !important;
    }
    .alert.alert-danger.text-left {
        display: flex;
        flex-direction: row;
        line-height: 2rem;
        margin-top: 1rem;
        opacity: 0;
    }
    .SendPostPage {
        .SendPostForm {
            .my-category-select-container .MuiFormControl-root {
                width: 100%;
            }

            ion-item ion-label {
                font-size: 18px;
            }

            .text-info {
                font-size: 13px;
            }

            .ios {
                .text-info {
                    font-size: 12px;
                }
            }

            .native-input.sc-ion-input-ios {
                font-size: 13px;
                padding-right: 0rem;
            }

            .native-input.sc-ion-input-md {
                font-size: 15px;
                padding-right: 0rem;
            }

            .facebook-column {
                .btn-facebook {
                    font-size: 16px;
                    margin: 1rem 0rem;
                }
            }

        }
    }

    .category-container {
        flex-wrap: wrap;
    }
}


.cityAutoComplete label {
    color: var(--color);
    padding: 0px 5px;
}

.cityAutoComplete input {
    color: var(--color);
    padding-left: 4px !important;
}

.cityAutoCompleteRTL input {
    padding-right: 8px !important;
}

.cityAutoComplete path {
    color: rgba(255, 255, 255, 0.3);
}

.cityAutoCompleteRTL label {
    right: 0;
    font-size: 18px;
    left: unset;
    padding-right: 20px;
}

.cityAutoCompleteRTL-focused {
    font-size: 22px;
}

.cityAutoComplete .MuiFormControl-marginNormal {
    margin-top: 0px;
    margin-bottom: 5px;
    border-color: #dcdcdc;
    border-width: thin;
    border-style: solid;
    border-radius: 6px;
}

.MuiInput-underline:after {
    border-bottom: 2px solid var(--ion-color-primary) !important;
}

.cityAutoCompleteRTL .MuiAutocomplete-inputRoot {
    padding-right: unset !important;
    padding-left: 56px;
}

.cityAutoCompleteRTL .MuiAutocomplete-endAdornment {
    right: unset !important;;
    left: 0;
}

.cityAutoCompleteRTL .MuiIconButton-root {
    color: #ababab
}

.cityAutoComplete .MuiIconButton-root {
    color: #ababab
}


.category-image {
    width: 10%;
    margin: 0px 10px;
}

.category-container {
    display: flex;
    flex-direction: row;
    padding: 0;
    text-align: center;

    ion-icon {
        width: 18px;
    }

    .MuiTypography-root {
        display: flex;
    }

    .MuiListItemSecondaryAction-root button:focus {
        color: var(--ion-color-medium);
        box-shadow: rgba(100, 100, 111, 0.2) 0px 14px 29px 0px;
        outline: none;
    }
}

.category-item {
    display: flex;
    flex-direction: column;

    .category-label-text {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

    ion-button {
        width: 99%;
    }
}

.modal-wrapper {
    border-radius: 1rem !important;
}

.category-modal {
    display: flex;
    flex-direction: column;

    .ion-page {
        justify-content: flex-start;
    }
}
