
.loginContainer {
    --background: #FAFBFD;
    padding: 1.2rem;
}

.login {
    padding: 1rem;

    color: rgb(17, 24, 39);
    margin: .3rem 20rem 1rem 20rem;
    border-radius: .5rem;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.20), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    background-color: #ffffff;

    ion-col:nth-child(3) ion-icon {
        margin-top: 0rem;
    }

    .title {
        font-size: 1.5rem;
        font-weight: 600;
    }

    .ion-column {
        display: flex;
        justify-content: center;

        .btn-facebook {
            white-space: nowrap;
            font-size: 1rem;
            width: 100%;
            background-color: #3B5998;
            color: white;
            display: block;
            border-radius: 18px;
            line-height: 29px;
            padding: 6px 16px;

            span {
                margin-left: 5px;
                margin-right: 5px;
            }

            ion-icon {
                font-size: 23px;
                padding-top: 2px;
            }
        }

        .btn-facebook:hover {
            opacity: 0.7;
        }
    }

    .separatorBottom {
        flex-basis: 100%;
        margin: 0rem 1rem .5rem 1rem;
        height: 1px;
        background: rgba(0, 0, 0, 0.315);
    }

    .alert-text-facebook {
        font-size: 14px;
        color: #721c24;
        background-color: #f8d7da;
        border-color: #f5c6cb;
        padding: 0.75rem 0.25rem 0.75rem 0.25rem;
        border: 1px solid transparent;
        border-radius: .5rem;
        display: flex;
        margin-top: 1rem;
        opacity: 0;

        ion-icon {
            width: 16%;
            padding: 4px 0px;
        }
    }

    .alert-success {
        align-items: center;
        display: flex;
        flex-direction: column;
    }

    .separator {
        display: flex;
        flex-basis: 100%;
        align-items: center;
        color: rgba(0, 0, 0, 0.315);
        margin: .5rem 1rem 0rem 1rem;
    }

    .separator:before,
    .separator:after {
        content: "";
        flex-grow: 1;
        background: rgba(0, 0, 0, 0.315);
        height: 1px;
        font-size: 0px;
        line-height: 0px;
        margin: 0px 8px;
    }

    .separator-2 {

        .sep {
            width: 3.1rem;
            border: none;
            height: 1px;
            margin: 0;
            flex-shrink: 0;
            background-color: rgba(0, 0, 0, 0.12);
        }
    }

    .font-semibold {
        font-weight: 600;

    }

    .label-floating.sc-ion-label-md-h {
        margin-bottom: 9px;
    }


    .alert.alert-danger {
        display: flex;
        flex-direction: row;
        line-height: 2rem;
        margin-top: 1rem;
        opacity: 0;

        ion-icon {
            width: 1.8rem;
            margin-right: 1rem;
        }
    }

    .btn-signup {
        border-radius: 18px;
        color: #252f3e;
        border: 1px solid rgba(37, 47, 62, 0.5);
        min-width: 64px;
        box-sizing: border-box;
        transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        font-family: Poppins, Roboto, "Helvetica", Arial, sans-serif;
        font-weight: 500;
        line-height: 1.75;
        text-transform: none;
    }

    .btn-signup:hover {
        border: 1px solid var(--ion-color-primary);
        color: var(--ion-color-primary);
    }

}

.font-normal {
    font-weight: 400;
}

a.font-normal {
    text-decoration: none;
    font-weight: 400;
}

a.font-normal:hover {
    text-decoration: underline;
    color: var(--ion-color-primary);
}

.loginForm {
    width: 100%;
    padding-left: .5rem;
    padding-right: .5rem;

    ion-item {
        --padding-end: 0;
        --padding-start: 0;
        --inner-padding-end: 0;
        --inner-padding-start: 0;


    }


    .submit-button {
        color: #252525;
        background-color: #f8d683;
        padding: 6px 16px;
        font-size: 1rem;
        min-width: 64px;
        box-sizing: border-box;
        transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        font-weight: 500;
        line-height: 1.75;
        border-radius: 18px;
        text-transform: none;
        border: 0;
        margin: 0;
        display: inline-flex;
        outline: 0;
        position: relative;
        align-items: center;
        user-select: none;
        vertical-align: middle;
        -moz-appearance: none;
        justify-content: center;
        text-decoration: none;
        -webkit-appearance: none;
        -webkit-tap-highlight-color: transparent;

    }

    .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle, .btn-primary:focus {
        color: #252525;
        background-color: var(--ion-color-primary);
        border-color: inherit;
        box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.14);
    }

    .submit-button:hover {
        background-color: var(--ion-color-primary);
        color: #252525;
    }

    .submit-button:disabled {
        color: rgba(0, 0, 0, 0.26);
        box-shadow: none;
        background-color: rgba(0, 0, 0, 0.12);
        margin-left: auto;
        margin-right: auto;
    }


    .pass-input-container {
        display: flex;
        width: 100%;
        border-color: #dcdcdc;
        border-width: thin;
        border-style: solid;
        border-radius: .5rem;
        margin-bottom: 4px;
        height: 34px;

        ion-input {
            border-width: 0;
            margin-bottom: 0;
            height: 100%;
        }

        ion-icon {
            font-size: 27px;
            color: var(--ion-color-medium);
            align-self: flex-end;
            padding: 0px 5px 2px 5px;
        }

    }


    ion-input {
        border-color: #dcdcdc;
        border-width: thin;
        border-style: solid;
        border-radius: .5rem;
        margin-bottom: 4px;
        height: 24px;

        input {
            margin: 0px 5px;
            height: 32px;
        }
    }


    ion-label {
        padding: 13px 10px 0px 10px !important;
    }

    .item-has-focus .item-has-focus.label-floating.sc-ion-label-md-h, .item-has-focus .label-floating.sc-ion-label-md-h {
        font-size: 20px;
        color: var(--ion-color-primary-shade);
    }
}

footer {
    margin-top: 0rem !important;
}

@media all and(max-width: 1200px) {
    .login {
        margin: 1rem 10rem 2rem 10rem;
    }
}

@media all and(max-width: 991px) {
    .login {
        margin: 1rem 8rem 2rem 8rem;
    }
}

@media all and(max-width: 767px) {
    .loginContainer {
        padding: 1.2rem;
    }
    .login {
        margin: .3rem 0.1rem 1rem 0.1rem;

        .title {
            font-size: 1.3rem;
        }

        .ion-column {
            .btn-facebook {
                font-size: 15px;
            }
        }
    }

    /*.login {
        margin: 1rem 1rem 5rem 1rem;

        .ion-column {
            padding: 0rem 1rem;

            .btn-facebook {
                font-size: 16px;
            }
        }
    }*/
}

@media (max-width: 400px) {

    .loginContainer {
        padding: 0;
    }
    .login {
        padding: 7px;
        margin: 1rem 0;

        .alert-text-facebook {
            font-size: 13px;
        }
    }

}

@media (max-width: 321px) {
    .loginContainer {
        padding: 0;
    }
    .login {
        margin: 1rem 0;

        .alert-text-facebook {
            font-size: 13px;
        }
    }

}
