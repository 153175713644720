.card-container {
  overflow: hidden;
  width: 224px;
  height: 312px;
  border-radius: 8px;
  margin-bottom: 32px;
  box-shadow: 0 4.5px 22px 0 rgba(0, 0, 0, 0.06);
  background-color: #fff;

  .card-top {
    height: 245px;
    width: 100%;
    overflow: hidden;
    position: relative;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;

    .card-top-overlay {
      width: 224px;
      height: 245px;
      background: #000;
      z-index: 10;
      position: absolute;
      opacity: .25;
    }

    .card-top-options {
      position: -webkit-sticky;
      position: absolute;
      right: 1%;
      top: 1%;
      z-index: 130;

      ion-icon {
        color: #fff;
      }
    }

    .card-image img {
      width: 224px;
      height: 241px;
      object-fit: cover;
    }

    .card-bottom-fixed {
      position: -webkit-sticky;
      position: sticky;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 130;

      .card-timestamp {

        ion-icon {
          color: white;
          width: 16px;
          height: 16px;
        }

        .card-timestamp-date {
          color: #fff;
          font-size: 1rem;
        }
      }

      .card-moderate {
        background-color: var(--ion-color-secondary);
        width: 100%;
        height: 32px;
        border-color: transparent;
        bottom: 0;
        color: #fff;
      }

      .card-boost-info {
        background-color: rgba(0, 0, 0, 0.5);
        width: 100%;
        height: 32px;
        border-color: transparent;
        bottom: 0;
      }
    }
  }

  .card-header-bottom {
    cursor: pointer;
  }
}
