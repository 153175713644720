
.gallery-selector {
    //background: #92b0b3;
    position: relative;
    height: 70%;
    width: 100%;
    margin: 1.4rem 0;
    box-shadow: 0 0 .5rem -.2rem #414141;
    outline: 2px dashed #92b0b3;
    outline-offset: -.7rem;
    -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
    transition: outline-offset .15s ease-in-out, background-color .15s linear;
    border-radius: 12px;
  
    .box__input {
      padding: .5rem;
      text-align: center;
  
      .box__title {
        position: absolute;
        width: 100%;
        color: var(--ion-color-medium);
        font-size: 3rem;
        font-weight: bolder;
        opacity: .5;
      }
  
      ion-icon {
        font-size: 3rem !important;
        width: 6rem;
        height: 6rem;
        padding: 5rem 0;
        color: var(--ion-color-primary);
      }
  
      .clickToChose:hover {
        color: var(--ion-color-primary);
      }
  
      .box__dragndrop {
        display: block;
      }
  
      p {
        font-size: 20px;
      }
    }
  
    .viewer {
      margin-top: 2rem;
      overflow-y: auto;
      height: calc(100% - 4rem);
      display: flex;
      ul.gallery {
        list-style: none;
        margin: auto;
  
        li.gallery-item {
          float: left;
          height: 7rem;
          width: 7rem;
          margin: .5rem;
  
          img {
            height: 100%;
            width: 100%;
          }
        }
  
        li.gallery-item:hover {
          opacity: .5;
          text-align: center;
          position: relative;
        }
  
        li.gallery-item:hover:before {
          content: 'x';
          clear: both;
          color: red;
          position: absolute;
          margin: auto;
          width: 100%;
          height: 100%;
          line-height: 7rem;
          font-weight: bold;
          font-size: 2rem;
        }
      }
    }
  }
  
  .gallery-selector.is-dragover {
    background: var(--ion-color-secondary-shade);
    outline-offset: -1rem;
    outline-color: #ffffff;
  
    .box__input {
  
      .box__title {
        color: var(--ion-color-light);
      }
    }
  }
  
  @media all and (max-width: 767px) {
    .gallery-selector {
      position: initial;
      min-height: 13rem;
      height: unset;
      width: 95%;
      margin-top: 0;
      margin-bottom: 0.4rem;
  
      .box__input {
        padding: .5rem;
  
        .box__title {
          font-size: 2.5rem;
          width: 92%;
        }
  
        ion-icon {
          padding: 5rem 0 0 0;
          height: 3rem;
          width: 3rem;
        }
        p {
          font-size: 16px;
        }
  
      }

      .viewer{
        margin-top: 0;
      }
  
      .viewer ul.gallery li.gallery-item {
        padding: .7rem;
        height: 5rem;
        width: 5rem;
      }
  
      .viewer ul.gallery li.gallery-item:hover:before {
        line-height: 5rem !important;
      }

      .viewer ul.gallery li.icon-add{
        padding: .7rem;
        height: 5rem;
        width: 5rem;

      }
  
    }
    
  }
  