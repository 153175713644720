footer {
  background-color: var(--ion-color-secondary);
  margin-top: 5rem;
  height: auto;
}
.foot-nav {
  max-width: 1020px;
  margin: 20px auto;
}
/*html[dir="RTL"] {
  footer ion-col {
    border-left: 1px solid var(--ion-color-light-tint);
    padding: .5rem 2rem;
  }

  footer ion-col:last-child {
    border-left: 0;
  }
}

html[dir="LTR"] {
  footer ion-col {
    border-right: 1px solid var(--ion-color-light-tint);
    padding: .5rem 2rem;
  }

  footer ion-col:last-child {
    border-right: 0;
  }
}*/


footer ion-col img {
  color: var(--ion-color-light);
}

footer ul.socialIcons, footer ul.storeIcons {
  list-style: none;
  text-align: center;
  padding: 0px;
}

footer ul.socialIcons li {
  display: inline;
  margin: .3rem;
}

footer ul.storeIcons {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
}

footer ul.storeIcons li img {
  height: 2rem;
}


footer ul.storeIcons li {
  flex: 1;
  margin: .5rem;
}

footer ul.socialIcons li ion-icon {
  color: var(--ion-color-light-tint) !important;
}

footer ul.socialIcons li ion-icon:hover {
  color: var(--ion-color-primary) !important;
}

footer ul.nav a, footer ul.nav h2 {
  color: var(--ion-color-light-tint) !important;
  overflow-wrap: break-word;
}

footer ul.nav a:hover {
  color: var(--ion-color-primary) !important;
}

// .FooterMobile div div{
//   z-index: 500;
//   /* opacity: 1; */
//   position: fixed;
// }
// .FooterWeb {
//   // margin: 13.1rem 0 0 0 !important;
// }
.FooterMobile {
  position: fixed !important;
  bottom: 0;
  background-color: white;
  z-index: 999;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 7px 0px,
              rgba(0, 0, 0, 0.14) 0px 2px 2px 0px,
              rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
  height: 3.5rem;          
}

.FooterMobile ion-row {
  text-align: center;
}

.FooterMobile ion-row ion-col ion-icon {
  width: 100%; 
  margin-bottom: -0.6rem;
  height: 60%;
}

.bg-col{
  background-color: var(--ion-color-primary) ;
}
.bg-col a{
 color: white;
}

@media all and (max-width: 767px) {
  footer ion-col {
    border: 0!important;
  }
  .FooterWeb {
    display: none;
  }
}

@media all and(min-width: 768px) {
  .FooterMobile{
    display: none;
  }
}

