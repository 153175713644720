/*.post-item-container {
  position: relative;

  .card-top-options {
    position: absolute;
    right: 1.5%;
    top: 1.5%;
  }

  .post-item {
    img {
      width: 150px;
      height: 150px;
      object-fit: cover;
    }

    .annonce-star-bg{
      background-color: rgb(254, 246, 233);
    }

    .media-body {
      height: 100%;
    }

    ion-card-header {
      ion-card-title {
        font-weight: 700;
      }

      ion-card-subtitle {
        font-weight: 700;

        span {
          color: #333;
          font-size: 16px;
        }
      }
    }
  }

  .body-bottom {
    padding-inline: 16px;
  }

  .my-ion-badge {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    height: 23px;
    right: 0;
    margin-right: 18px;
    margin-bottom: 7px;
  }
}*/

/*html[dir="RTL"] .post-item-container {
  .post-item {
    .my-ion-badge {
      left: 0 !important;
      right: unset !important;
      margin-right: unset !important;
      margin-left: 18px;
    }
  }
}*/

.pagination {
  justify-content: center;
  padding: 0 0;
}

.page {
  display: flex;
  justify-content: center;
  margin: 0 0.1rem;
}

.break-me {
  /*border-radius: 0.5rem;
  border: 1px solid #dee2e6;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;*/
  display: none;
}

.break-me:hover {
  background-color: #dee2e6;
}

.page-link {
  font-size: 20px;
  border-radius: 0.5rem;
  color: var(--ion-color-dark);
}

.active {
  color: var(--ion-color-primary);
}

.active-link {
  border: 1px solid var(--ion-color-primary) !important;
}

.prev {
  background-color: var(--ion-color-primary);
  width: 30rem;
  margin-left: 1rem;
  border-radius: 0.5rem;
  display: flex;
  text-align: center;
  flex-direction: column;
  font-size: 20px;
  font-weight: bold;
}

.prev-link {
  padding-top: 8px;
  color: var(--ion-color-dark) !important;
}

.next-link {
  padding-top: 8px;
  color: var(--ion-color-dark) !important;
}

.next:hover {
  background-color: var(--ion-color-dark) !important;
  color: var(--ion-color-primary) !important;
}

.prev:hover {
  background-color: var(--ion-color-dark) !important;
  color: var(--ion-color-primary) !important;
}

.next-link:hover {
  color: var(--ion-color-primary) !important;
}

.prev-link:hover {
  color: var(--ion-color-primary) !important;
}

.next {
  background-color: var(--ion-color-primary);
  width: 30rem;
  margin-right: 1rem;
  border-radius: 0.5rem;
  display: flex;
  text-align: center;
  flex-direction: column;
  font-size: 20px;
  font-weight: bold;
}


.my-custom-class {
  .ion-page {
    display: flex;
    flex-direction: row;

  }
}

@media (max-width: 991px) {

}

@media (max-width: 480px) {

  /*.post-item-container {
    .post-item {
      img {
        width: 116px;
        height: 116px;
        object-fit: cover;
      }

      ion-card-header {
        padding: 6px 0 4px 4px;

        ion-card-title {
          font-size: 16px;
          line-height: 1.2;
        }

        ion-card-subtitle {
          margin-top: 0;
          text-transform: inherit;

          span {

            font-size: 14px;
          }
        }
      }

      .body-bottom {
        padding-inline: 4px;
        font-size: 10px;

        .body-bottom-category {
          font-size: 12px;
        }
      }

      .my-ion-badge {
        height: 20px;
        margin-right: 3px;
        margin-bottom: 3px;
      }
    }
  }*/
  html[dir="RTL"] .post-item-container {
    .post-item {
      ion-card-header {
        padding: 6px 4px 4px 0;
      }

      .my-ion-badge {
        left: 0 !important;
        right: unset !important;
        margin-right: unset !important;
        margin-left: 3px;
      }
    }
  }

  .page {
    margin: 0 0.1rem;
  }

  .page-link {
    font-size: 13px;
    padding-inline: 0.5rem !important;
  }

  .prev {
    margin-left: 0rem;
    font-size: 13px;
  }
  .next {
    margin-right: 0rem;
    font-size: 13px;
  }
}

.stylesNoResultMessages {
  font-size: 1.3em;
  color: #1a1a1a;
  line-height: 1.5;
  box-sizing: inherit;
  text-align: center;
  padding-bottom: 2.4rem;
}

.stylesNoResultMessages {
  min-height: 22rem;
}

.styles_Notfound {
  box-sizing: border-box;
  display: inline-block;
  overflow: hidden;
  width: 250px;
  height: 187px;
  position: relative;
}

#not_found {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  box-sizing: border-box;
  padding: 0;
  border: none;
  margin: auto;
  display: block;
  width: 0;
  height: 0;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
}

.MessageNotFound {
  text-align: center;
  box-sizing: inherit;
}

