.zones ion-row ion-col ion-list ion-list-header h2 {
  text-transform: capitalize;
}

.zones ion-row ion-col ion-list ion-item a {
  color: var(--ion-color-dark);
  display: block;
  width: 100%;
  text-decoration: none;
  text-transform: capitalize;
}

.zones ion-row ion-col ion-list ion-item:hover a {
  color: var(--ion-color-primary);
}