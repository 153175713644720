.grid-popular-cities-container {
    background-color: var(--ion-color-light-tint);
    margin: 3rem 0rem;
    border-radius: 1.25rem;
    .popular-cities-list {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        ion-chip {
            --background: var(--ion-color-secondary-tint);
            font-weight: 500;
            a {
                color: var(--ion-color-dark);
            }
        }
        ion-chip:hover {
            --background: var(--ion-color-primary);
        }
    }
}
.city-modal-class {

    .modal-wrapper {
        height: 10rem;
        width: 44rem;
        .ion-page {
            display: flex;
        }
    }
    .city-modal-container {
        
        .city-title-row {
            margin: 1rem 0rem 2rem 0rem;
        }

        .city-container {
            .city-button-column {
                ion-button {
                    --border-radius: .5rem;
                    width: 13rem;
                    height: 82%;
                }
            }
           
        }
        .label-container-city {
            width: 100%;
        }
        
    }
    
   

}



@media (max-width: 767px) {
 
    .grid-popular-cities-container {
        margin: 1rem 1rem;
    }

    .city-container {
        .city-button-column {
            ion-button {
                font-size: 11px;
                width: 7.8rem !important;
            }
        }
       
    }
}
