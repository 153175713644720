.post-item.featured {
  margin: 0px 0px 1rem 0px;

  span.budget {
    position: relative;
    top: 0.5rem;//991
    left: -.5rem;
    z-index: 20;
    background-color: var(--ion-color-primary);
    padding: .3rem;
    color: var(--ion-color-light);
    font-weight: 700;
    font-size: .7rem;
  }

  ion-card{
    width: 100%;
    border-radius: 6px;
  }

  span.budget:after {
    content: '';
    position: absolute;
    bottom: -.5rem;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 .5rem .5rem 0;
    border-color: transparent var(--ion-color-primary-shade) transparent transparent;
  }

  .title {
    color: var(--ion-color-dark);
    font-size: 1rem;
    font-weight: bold;
  }

  .price {
    color: var(--ion-color-primary);
    font-weight: bold;
  }

  .detail {
    padding: 0rem;
    margin-bottom: .5rem;
    margin-top: .5rem;
  }
}

.post-item.featured:hover .title {
  color: var(--ion-color-primary);
}

@media (max-width: 991px) {
  .post-item.featured span.budget {
    //top: -21rem;
  }
}

html[dir="RTL"]{
  .post-item.featured{
    span.budget{
      left: unset;
      right: -.5rem;
    }

    span.budget:after{
      left: unset;
      right: 0;
      border-width: 0.5rem 0.5rem 0 0rem;
      border-color: var(--ion-color-primary-shade) var(--ion-color-primary-shade) var(--ion-color-primary-shade) var(--ion-color-primary-shade);
    }
  }
}
