/*.category-radium-container {
  padding: 0px 9rem;
}*/

.categories-box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: center;
  ion-card {
    border-radius: .8rem;
    transition-property: transform, box-shadow;
    transition-duration: 0.25s;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 14px 29px 0px;
  }
  ion-card:hover {
    transform: translate(0px, -10px);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 28px 29px 0px;
  }
  
}

.category {
  width: 103px !important;
  height: 167px;
 
  a {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    p {
      text-transform: uppercase;
      font-size: 13px;
      font-weight: bold;
      line-height: normal;
      letter-spacing: 1px;
      text-align: center;
      margin-top: 8px;
    }
    img {
      max-width: 80px;
    }
   
  }
  a:link {
    text-decoration: none;
  }
  a:hover {
    color: var(--ion-color-medium);
  }
}

@media (max-width: 991.98px) {
  .category-radium-container {
    margin-left: 1rem;
    margin-right: 1rem;
    padding: 0;

    .categories-box ion-card {
      margin: .2rem;

      ion-card-content {
        padding: .2rem;
      }
  }

    .category {
      width: 90px !important;
      height: 110px;

      a {
        p {
          font-size: 11px;
        }
      }
      
    }
  }
}
