.sliderContainer2 {
    position: relative;
    .price{
      font-weight: bold;
     }

   ion-slide {
     height: 360px;
   }

   .slide_card {
    height: 358px;
       width: 100%;
    border-radius: .8rem;
   }

   .slide_card div.details p {
    margin-top: 0rem;
    margin-bottom: 0rem;
    font-size: 12px;
    line-height: 1.3;
  }
  
  .slide_card img{
    height: 212px;
      object-fit: cover;
      width: 100%;
  }

  .date-info {
    font-size: 12px;
    margin-top: 5px;
  }
    
}
  .slide_card:hover {
    -webkit-box-shadow: 0px 0px 10px 0px var(--ion-color-medium-tint);
    -moz-box-shadow: 0px 0px 10px 0px var(--ion-color-medium-tint);
    box-shadow: 0px 0px 10px 0px var(--ion-color-medium-tint);
  }
  
  .nextBtn2 {
    position: absolute;
    z-index: 10;
    top: 40%;
    height: 34px;
    width: 34px;
    cursor: pointer;
    background-color: #f5f6f9;
    right: 0;
    border-radius: 1.25rem;
}

  .prevBtn2 {
    position: absolute;
    z-index: 10;
    top: 40%;
    height: 34px;
    width: 34px;
    cursor: pointer;
    background-color: #f5f6f9;
    left: 0;
    border-radius: 1.25rem;
  }
  
  

  
  @media (max-width: 991px) {

  }


  @media (max-width: 480px) {
    .sliderContainer2 {
    .slide_card img{
      height: 160px;
    }
    .slide_card {
      height: 300px;
     }
      
       .post_title{
      font-size: 13px;
      font-weight: bold;
      height: 29.5px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      height: 30px;
    }
    .price{
      font-size: 11px;
     }
  
      
  }   
  }

