.search-modal-grid {
    margin-top: 7px;
    .item-query {
        display: flex;
        align-items: center;
        ion-label {
            margin-top: 0;
        }
    }

    .category-region-container {
        margin-top: -15px;
        .category-select-container {
            margin-bottom: .5rem;

        }
        .region-select-container {
            margin-top: .5rem;
        }

        .sub-category-container {
            border: 1px solid var(--ion-color-light-shade);
            margin-top : 5px;
            margin-bottom: 5px;
            border-radius: 1rem;
            text-align: center;
            .sub-categories-box {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;

                ion-card {
                    margin: 2px 2px;
                    border-radius: 1rem;
                    width: 156px;

                    ion-card-content {
                        padding: 5px;
                        
                        ion-item {
                            border-radius: 1rem;
                        }
                    }
                    
                    ion-card-content ion-item p {
                        width: 100%;
                    }
                    ion-card-content ion-item ion-avatar {
                        margin-left: 10px;
                        margin-right: 10px;
                        display: flex;
                        align-items: center;
                    }
                }
                
            }
        }
    }  
}
.menuux{
    
    border-bottom: 1px solid #e6ebef !important;
    margin-left: 15px !important;
    margin-right: 15px !important;
    font-family: Open Sans,sans-serif,Arial!important;
    -webkit-text-size-adjust: 100%!important;
    font-size: 1.3em!important;
    color: #1a1a1a!important;
    line-height: 1.5!important;
    align-items: center!important;
    box-sizing: content-box!important;
    min-height: 3rem!important;
    text-align:justify !important;
    
    }


.erase-button {
    ion-buttons {
        justify-content: flex-end;
    }
}