.boost-choice {
  font-size: 12px;
  color: rgb(84, 84, 84);
  width: 100%;
  -webkit-box-align: center;
  align-items: center;

  span.period {
    font-size: 14px;
    letter-spacing: 1.25px;
    font-weight: 500;
    color: rgb(74, 74, 74);
  }
   ion-label {
    font-size: 12px;
    font-weight: 500;
    color: rgb(102, 102, 102);
    text-transform: uppercase;
  }
}