.contactPage {
  --background: #f8f8f8;

  .contactForm {
    margin: 1rem 20%;
    border-radius: .5rem;
    box-shadow: 0rem 0rem .2rem -.1rem var(--ion-color-dark);
    background-color: #ffffff;

    ion-text {
      font-size: .6rem;
    }
  }

}
.mym-100 {
  margin-bottom: 100px !important;
  margin-top: 100px !important;
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.mbm-100 {
  margin-bottom: 100px !important;
  padding-right: 0px !important;
  padding-left: 0px !important;
}
@media all and(max-width: 1200px) {

  .contactPage {
    .contactForm {
      margin: 1rem 10%;
    }
  }
}

@media all and(max-width: 991px) {
  .contactPage {
    .contactForm {
      margin: 1rem 5%;
    }
  }
}

@media all and(max-width: 767px) {

  .contactPage {
    .contactForm {
      margin: 1rem .1rem;
    }
  }
}

.userConnected {
  display: none !important;
}

.userNotConnected {
  display: block !important;
}