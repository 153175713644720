.corner-ribbon {
  width: 250px;
  background: #e43;
  position: absolute;
  top: 25px;
  left: -50px;
  text-align: center;
  line-height: 50px;
  letter-spacing: 1px;
  color: #f0f0f0;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  overflow: hidden;
  z-index: 999999;
}

.corner-ribbon.shadow {
  box-shadow: 0 0 3px rgba(0, 0, 0, .3);
}

/* Different positions */

.corner-ribbon.top-right {
  /* top: 18px; */
  top: 30px;
  right: -70px;
  left: auto;
  transform: rotate(45deg);
  -webkit-transform: rotate(46deg);
  overflow: hidden;
}

.corner-ribbon.blue {
  background: var(--ion-color-primary);
}


.my-ion-content {
  --background: var(--ion-background-color, rgb(248, 248, 248));
}

.my-ion-content-mobile {
  --background: var(--ion-background-color, rgb(248, 248, 248));
  max-height: 82%;
}

.mobile-modal-container {
  width: 100%;
}

.user {
  margin: 0rem 0rem 8rem 0rem;
}

.user ion-avatar {
  height: 5rem;
  width: 5rem;
  margin: 1rem;
}

.user ion-item ion-label {
  margin-left: 1rem;
}

.user ion-card-header ion-item {
  font-size: .7rem;
}

.bottomShareButtons, .bottomShareButtons ion-col {
  padding: 0rem;
}

.bottomShareButtons ion-item {
  font-size: .8rem;
  color: var(--ion-color-dark);
}

.bottomShareButtons ion-item ion-label {
  margin: 0rem 1rem;
}

.bottomShareButtons ion-item:hover ion-label,
.bottomShareButtons ion-item:hover ion-icon {
  color: var(--ion-color-primary);
}

.userCallInfo {
  display: none;
  position: fixed;
  bottom: 0;
  z-index: 10;
  width: 100%;

  ion-grid, ion-col {
    padding: 0rem;
    margin: auto 0rem;

    ion-icon, ion-label {
      color: var(--ion-color-light) !important;
      font-size: 10px;
    }

    ion-icon {
      margin-right: 3px;
      padding-bottom: 4px;
    }

  }
}

.user-info-mini {
  background-color: white;
  box-sizing: border-box;
  padding: 16px;
  width: 100%;
  height: 96px;
  box-shadow: rgba(0, 0, 0, 0.05) 0 -9px 28px 0;
  position: sticky;
  bottom: 0px;
  z-index: 1020;
  border-bottom: 1px solid rgb(234, 234, 234);

  picture {
    display: initial;
    height: 64px;
    width: 64px;
    flex: 0 0 auto;
    object-fit: cover;
    border-radius: 50%;
  }

  .wtsp-btn {
    picture {
      object-fit: contain;
      flex-shrink: 0;
      height: 30px;
      width: 30px;
      aspect-ratio: auto 30 / 30;
    }
  }

  .call-icon {
    font-size: 30px;
  }

  .user-detail {
    margin-left: 12px;
    margin-right: 12px;
  }

  ion-button {
    font-size: 0.875rem;
    font-weight: 600;
  }

}

.detailScreen {
  .annonce-desactivated-msg {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .MuiTypography-root {
    margin: 10px 0px 10px 0px !important;
  }

  .MuiBreadcrumbs-li {
    color: var(--ion-color-primary);
  }

  ion-button {
    ion-icon {
      padding: 0 7px 0 7px;
    }
  }

  .detail-container {
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.07) 0 2px 18px 0;
    background-color: rgb(255, 255, 255);
    overflow: hidden;

    ion-card {
      box-shadow: none;
    }

    .post-detail {
      border-bottom: 1px solid rgb(216, 216, 216);

      ion-card-header {
        background-color: #fff;
      }

      .location {
        color: rgb(45, 45, 45);

        .ion-icon {
          width: 14px;
          height: 14px;
        }

        span {
          font-size: 14px;
          font-weight: 400;
          letter-spacing: 0.1px;
          margin-right: 16px;
          margin-left: 8px;
        }
      }

      .date {
        color: rgb(45, 45, 45);

        .ion-icon {
          width: 14px;
          height: 14px;
        }

        span {
          font-size: 14px;
          font-weight: 400;
          letter-spacing: 0.1px;
          margin-right: 16px;
          margin-left: 8px;
        }
      }

      .views {
        color: rgb(45, 45, 45);

        .ion-icon {
          width: 14px;
          height: 14px;
        }

        span {
          font-size: 14px;
          font-weight: 400;
          letter-spacing: 0.1px;
          margin-right: 16px;
          margin-left: 8px;
        }
      }

      .price {
        font-weight: 400;
        font-size: 24px;
      }

      ion-card-title {
        margin: 0;

        h1 {
          margin-top: 16px;
          margin-bottom: 16px;
          font-size: 20px;
          font-weight: 400;
        }
      }
    }

    .post-more-detail {
      margin-bottom: 16px;

      .detail-list {
        padding: 0;
        margin: 0;
        box-sizing: border-box;
        list-style: none;
        width: 100%;

        .detail-list-item {
          box-sizing: border-box;
          height: 50px;
          width: 100%;
          padding: 0 16px;
          background-color: rgb(255, 255, 255);
          border-bottom: 1px solid rgb(230, 230, 230);

          .list-type-title {
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0.5px;
            color: rgb(85, 85, 85);
          }

          .list-type-content {
            color: rgb(38, 38, 38);
            font-weight: bold;
            text-align: right;
            margin-left: 8px;
          }
        }


      }
    }

    .post-desc {
      padding: 0 16px;

      .desc {
        color: rgb(102, 102, 102);
        display: inline;
        line-height: 1.5;
      }

      h2 {

        font-size: 16px;
        margin: 1rem 0rem;
      }

      p {
        font-weight: 400;
        font-size: 16px;
      }

      .report-link {
        margin-bottom: 16px;

        ion-icon {
          height: 19px;
          width: 19px;
          color: rgb(179, 46, 63);
        }

        span {
          color: rgb(179, 46, 63);
          font-size: 12px;
          font-weight: 400;
          letter-spacing: 0.4px;
          margin-left: 8px;
        }
      }

      .report-link:hover {
        text-decoration: none;
      }
    }

  }

  .text-note {
    color: rgb(74, 74, 74);
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.1px;
    text-align: center;
  }

  .post-recom {
    span {
      font-size: 18px;
      font-weight: 500;
      line-height: normal;
      letter-spacing: normal;
      color: rgb(45, 45, 45);
    }
  }

  .user-info {
    padding: 24px;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.07) 0 2px 18px 0;
    background-color: rgb(255, 255, 255);

    .user-info-avatar {
      object-fit: contain;
      flex-shrink: 0;
      border-radius: 50%;
      height: 64px;
      width: 64px;
      aspect-ratio: auto 64 / 64;
    }

    .wtsp-btn {
      picture {
        object-fit: contain;
        flex-shrink: 0;
        height: 30px;
        width: 30px;
        aspect-ratio: auto 30 / 30;
      }
    }

    .user-detail {
      margin-left: 12px;
      margin-right: 12px;
      width: calc((100% - 64px) - 12px);

      p {
        font-weight: 500;
        line-height: 1.56;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100%;
        color: rgb(45, 45, 45);
        font-size: 20px;
        letter-spacing: 0.15px;
        margin: 0;
      }
    }

    ion-button {
      font-size: 0.875rem;
    }
  }
}

@media (min-width: 768px) {
  .detailScreen {
    .detail-container {
      .post-more-detail {
        .detail-list {
          //max-height: calc(100% - 50px);
          .detail-list-item {
            width: calc(50%);
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .detailScreen .annonce-desactivated-msg {
    font-size: 13px;
  }
  .leftAdContainer {
    display: none !important;
  }

  .mpr-1 {
    padding-right: 1rem !important;
  }

  .mpl-1 {
    padding-left: 1rem !important;
  }

  .paddingBottomMedia {
    padding-bottom: 3rem !important;
  }

  .bottomAdContainer {
    margin-bottom: 4rem !important;
  }

  .detailScreen.container {
    padding: 0rem !important;
    margin: 0rem !important;
    width: 100% !important;
    max-width: 100% !important;
  }
  /*.detailScreen {
      .user-info{
          margin-bottom: 10rem;
      }
  }*/
  .detailScreen.container ion-col, .detailScreen.container ion-grid:first-child {
    padding: 0rem !important;
  }

  .detailScreen.container ion-card:first-child {
    border-radius: 1rem !important;
  }
  .userCallInfo {
    display: initial !important;
  }
  .ios {
    .userCallInfo {
      background-color: transparent;

      ion-icon {
        margin-left: 3px;
      }
    }
  }
}

@media (max-width: 400px) {
  .user-info-mini {
    height: 72px;

    .user-info-avatar {
      display: none;
    }

    .wtsp-btn {
      picture {
        object-fit: contain;
        flex-shrink: 0;
        height: 30px;
        width: 30px;
        aspect-ratio: auto 30 / 30;
      }
    }

    .user-detail {
      margin: 0;

      h3 {
        font-size: 18px;
        margin: 0;
      }
    }

  }

}

/*.marque {
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
    list-style: none;
    width: 100%;
    display: flex;
    flex-flow: column wrap;
}

.modele {
    box-sizing: border-box;
    height: 50px;
    width: 50%;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    padding: 0px 16px;
}*/
