.sliderContainer {
    position: relative;

    .price {
        font-weight: bold;
    }
}

.slide_card:hover {
    -webkit-box-shadow: 0px 0px 10px 0px var(--ion-color-medium-tint);
    -moz-box-shadow: 0px 0px 10px 0px var(--ion-color-medium-tint);
    box-shadow: 0px 0px 10px 0px var(--ion-color-medium-tint);
}

.nextBtn {
    color: rgb(102, 102, 102);
    position: absolute;
    z-index: 10;
    right: 15px;
    top: 40%;
    height: 40px;
    width: 40px;
    cursor: pointer;
    background-color: white;
    border: 1px solid rgb(155, 155, 155);
    border-radius: 50%;
}

.prevBtn {
    color: rgb(102, 102, 102);
    position: absolute;
    z-index: 10;
    left: 15px;
    top: 40%;
    height: 40px;
    width: 40px;
    cursor: pointer;
    background-color: white;
    border: 1px solid rgb(155, 155, 155);
    border-radius: 50%;
}

.slide_card {
    height: 337px;
    margin-left: 0;
    margin-right: 11px;
}

.slide_card img {
    height: 200px;
    object-fit: cover;
}

.slide_card div.detail p {
    margin: 0px;
    padding: 0px;
    font-size: 1rem;
    line-height: 1.3;
}

.details {
    font-size: 12px;
    margin-top: 5px;
}

.post_title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 48px;
}

@media (max-width: 480px) {
    .sliderContainer {
        .slide_card img {
            height: 160px;
        }

        .slide_card {
            height: 280px;
        }

        .post_title {
            font-size: 13px;
            font-weight: bold;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            height: 30px;
        }

        .price {
            font-size: 11px;
        }

        .details {
            font-size: 12px;
            margin-top: 5px;
        }
    }
}

@media (max-width: 330px) {
    .ios {
        .post_title {
            font-size: 12px;
            font-weight: 400;
        }

        .price {
            font-size: 8px;
        }

        .details {
            font-size: 9px;
            margin-top: 0px;
        }
    }

}
