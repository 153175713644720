
.post-item-ua {
    padding: 0rem 0rem 0rem 0rem !important;
    margin: 1rem !important;;

    transition: all 0.3s ease-in-out !important;
    border: 0px solid var(--ion-color-primary) !important;
    border-radius: 1rem !important;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07),
    0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07),
    0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07) !important;

    .modify-delete-buttons {
        display: flex;

        ion-button {
            width: 100%;
            max-height: 27px;

            p {
                width: 100%;
                margin-bottom: 0;
                font-size: 11px;

            }
        }
    }
}

.post-item-ua:hover {
    -webkit-box-shadow: 0px 0px 10px 0px var(--ion-color-medium-tint) !important;
    -moz-box-shadow: 0px 0px 10px 0px var(--ion-color-medium-tint) !important;
    box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.309) !important;
}

.post-item-ua ion-grid, .post-item-ua ion-col:first-child {
    margin: 0rem 0rem 0rem 0rem;
    padding: 0;
}

.post-item-ua ion-item {
    border-radius: 1rem !important;
}

.post-item-ua:hover {
    -webkit-box-shadow: 0px 0px 10px 0px var(--ion-color-medium-tint);
    -moz-box-shadow: 0px 0px 10px 0px var(--ion-color-medium-tint);
    box-shadow: 0px 0px 10px 0px var(--ion-color-medium-tint);
}

.post-item-ua.hover {
    -webkit-box-shadow: 0px 0px 10px 0px var(--ion-color-medium-tint);
    -moz-box-shadow: 0px 0px 10px 0px var(--ion-color-medium-tint);
    box-shadow: 0px 0px 10px 0px var(--ion-color-medium-tint);
}

.text-center-ua {
    width: 89%;
    display: flex;
    flex-direction: row;
    line-height: 2rem;
    margin-top: 1rem;

    ion-icon {
        margin: 0px 5px;
    }

}

.text-no-post {
    text-align: center;

}

.post-item-ua ion-button button {
    height: inherit;
}


html[dir="RTL"] .post-item-ua div.detail-ua div {
    text-align: left;
}

html[dir="LTR"] .post-item-ua div.detail-ua div {
    text-align: right;
}

.post-item-ua div.detail-ua {
    margin: 0px;
    padding: 10px 0px 0px 0px;
    font-size: 13px;
    line-height: 0.2;

}

.post-item-ua .img-ua {
    min-height: 208.3px;

}

.post-item-ua span.title {
    color: var(--ion-color-dark);
    font-size: 1rem;
    display: block;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-right: 0rem;
    padding-bottom: 3px;
}

.post-item-ua:hover span.title {
    color: var(--ion-color-primary);
}

.post-item-ua span.price {
    color: var(--ion-color-primary);
    margin-top: 0px;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: bold;
}

.post-item-ua div.detail-ua {
    margin-right: 0rem;
    margin-bottom: .5rem;

}

.post-item-ua div.detail p {
    margin: 0px;
    padding: 0px;
    font-size: 1rem;
    line-height: 1.3;
}

.post-item-ua div.right {
    padding: 1rem 0.5rem 0rem .5rem;
    display: flex;
    flex-direction: column;
    height: 100%;

}

.post-item-ua div.right > div {
    flex: 1;
}


@media all and(max-width: 767px) {


    .text-center-ua {
        width: 100%;

    }

}

@media (max-width: 480px) {
    .post-item-ua {
        margin: .5rem 0rem;

    }


    .post-item-ua .img-ua {
        min-height: 100%;

    }
}

ion-alert .alert-wrapper .alert-radio-group {
    width: 400px;
    align-items: baseline;
}

ion-alert .alert-wrapper {
    --min-width: 400px;
    --max-width: 400px;
    --width: 400px;
}

.inModeration {
    color: red;
    font-weight: bold;
}


