header.navbar {
  box-shadow: 0px 0px 10px -3px var(--ion-color-dark);
  z-index: 12;
  padding: 0rem .5rem;
  height: auto;
}

header.navbar-light {
  background-color: var(--ion-color-light) !important;
}

header.navbar .navbar-brand {
  color: var(--ion-color-primary);
  margin: 0;
}

header.navbar .navbar-brand img {
  height: 3rem;

}

header a, header ion-router-link {
  color: var(--ion-color-dark) !important;
  text-align: center;
}

header a:hover, header ion-router-link:hover {
  color: var(--ion-color-primary) !important;
  text-decoration: none;
}

header div .navbar-collapse a:hover, header div .navbar-collapse a:hover {
  color: var(--ion-color-primary) !important;
}

header ul a span, header ul ion-router-link span, header #headerSearchIcon span {
  display: block;
  color: var(--ion-color-dark) !important;
}

header ul a:hover span, header ul ion-router-link:hover span, header #headerSearchIcon:hover span {
  color: var(--ion-color-primary) !important;
}

header ul li.nav-item.dropdown {
  margin: auto 0px auto 2rem;
}

header ul a svg, header ul ion-router-link svg {
  height: 1.5rem;
  margin-right: .5rem;
}

header .add-post {
  background-color: var(--ion-color-dark);
  color: var(--ion-color-light-tint) !important;
  border-radius: .5rem;
  padding: .4rem;
  margin: 0 2rem !important;
  -webkit-animation: glowing 1300ms infinite;
  -moz-animation: glowing 1300ms infinite;
  -o-animation: glowing 1300ms infinite;
  animation: glowing 1300ms infinite;
}

header .add-post div.add-post-content {
  display: flex;
  flex-direction: row;

  ion-icon {
    border-width: thin;
    border-color: white;
    border-style: solid;
    margin-right: -5px;
    margin-left: -5px;
    border-radius: 4px;
  }

}

header .add-post div.add-post-content div, header .add-post div.add-post-content span {
  margin: auto;
  height: 2rem;
  padding: 0 7px;
}

header .add-post:hover div.add-post-content div, header .add-post:hover div.add-post-content span {
  color: var(--ion-color-primary) !important;
}

header #headerSearchIcon {
  display: none;
  font-size: .9rem;
  align-content: center;
  justify-content: center;
}

header #shop-notification-count {
  position: absolute;
  right: 8%;
  top: 8%;
}

header #headerSearchIcon ion-icon {
  margin: 0rem 1rem;
}

.svgClass {
  border-radius: 10px;
  margin-top: 6px;
  margin-bottom: 4px;
  max-width: 40px;
  max-height: 40px;
}

.HeigthHeader {
  height: 65px;
}

ion-menu-toggle {
  display: none !important;
  font-size: .9rem;
  flex-direction: row;
  cursor: pointer;
  //--ripple-color: transparent;
  //--background-hover: transparent;
}

ion-menu-toggle span, ion-menu-toggle ion-icon {
  display: flex;
  flex: 1;
}

ion-menu-toggle ion-icon {
  margin: 0rem .1rem;
}

.notch-header {
  border-radius: .5rem;
  box-shadow: 0px 5px 13px -5px var(--ion-color-dark);

  ion-toolbar {
    border-radius: 1rem;
  }

  ion-buttons {
    width: 3rem;
  }

  .flaha-logo-container {
    width: 100%;
    display: flex;
    justify-content: center;

    .flaha-logo-pic {
      width: 50%;
    }
  }


  .envirenement-badge {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

html[dir="rtl"] ion-back-button {
  transform: rotate(180deg);
}

.dropdown-toggle span:after {
  display: inline-block;
  margin-left: 0.26em;
  vertical-align: 0.26em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

html[dir="rtl"] .dropdown-toggle span:after {
  display: inline-block;
  margin-left: unset !important;
  margin-right: 0.26em;
  vertical-align: 0.26em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:after {
  display: unset !important;
  margin-left: unset !important;
  vertical-align: unset !important;
  content: unset !important;
  border-top: unset !important;
  border-right: unset !important;
  border-bottom: unset !important;
  border-left: unset !important;
}

@media (max-width: 991px) {

  html[dir="LTR"] {
    header a, header ion-router-link {
      text-align: left !important;
    }
  }

  html[dir="RTL"] {
    header a, header ion-router-link {
      text-align: center !important;
    }
  }

  header .add-post {
    margin: 2rem auto;
    display: block;
  }

  header ul a span, header ul ion-router-link span {
    display: initial;
  }

  header ul a ion-icon, header ul ion-router-link ion-icon {
    height: 18px;
  }

  header ul li.nav-item.dropdown {
    margin: auto 0px auto 0px;
  }

  header #headerSearchIcon {
    display: initial;
    padding: 0rem .5rem;
  }

  ion-menu-toggle {
    display: initial !important;
  }
}

.langSelector {
  margin: 12px 30px 0px 30px;

  ion-label {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 767px) {
  .ios header.navbar {
    padding-top: 3px;
  }

  header.navbar {
    padding: 0;
  }

  ion-menu-toggle {
    margin-left: 0rem;
    padding: 0rem .5rem;
  }

  header #headerSearchIcon ion-icon {
    margin: 0.5rem .5rem 0rem .5rem;
  }

  header ul a span, header ul ion-router-link span, header #headerSearchIcon span {
    padding-bottom: 8px;
  }
}

@-webkit-keyframes glowing {
  0% {
    -webkit-box-shadow: 0 0 3px #ffa500;
  }
  50% {
    -webkit-box-shadow: 0 0 15px #ffa407;
  }
  100% {
    -webkit-box-shadow: 0 0 40px #f7a900;
  }
}

@keyframes glowing {
  0% {
    -webkit-box-shadow: 0 0 3px #ffa500;
  }
  50% {
    -webkit-box-shadow: 0 0 15px #ffa707;
  }
  100% {
    -webkit-box-shadow: 0 0 40px #f7b500;
  }
}