.corner-ribbon {
    width: 250px;
    background: #e43;
    position: absolute;
    top: 25px;
    left: -50px;
    text-align: center;
    line-height: 50px;
    letter-spacing: 1px;
    color: #f0f0f0;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    overflow: hidden;
    z-index: 999999;
  }
  .corner-ribbon.shadow {
    box-shadow: 0 0 3px rgba(0, 0, 0, .3);
  }
  /* Different positions */
  
  .corner-ribbon.top-right {
    /* top: 18px; */
      top:30px;
    right: -70px;
    left: auto;
    transform: rotate(45deg);
    -webkit-transform: rotate(46deg);
    overflow: hidden;
  }
  .corner-ribbon.blue {
    background: var(--ion-color-primary);
  }
  
  
  .my-ion-content {
      --background: var(--ion-background-color, rgb(248, 248, 248));
  }
  
  .my-ion-content-mobile {
      --background: var(--ion-background-color, rgb(248, 248, 248));
      max-height: 82%;
  }
  
  .mobile-modal-container {
      width: 100%;
  }
  
  .user {
      margin: 0rem 0rem 8rem 0rem;
  }
  
  .user ion-avatar {
      height: 5rem;
      width: 5rem;
      margin: 1rem;
  }
  
  .user ion-item ion-label {
      margin-left: 1rem;
  }
  
  .user ion-card-header ion-item {
      font-size: .7rem;
  }
  
  .bottomShareButtons, .bottomShareButtons ion-col {
      padding: 0rem;
  }
  
  .bottomShareButtons ion-item {
      font-size: .8rem;
      color: var(--ion-color-dark);
  }
  
  .bottomShareButtons ion-item ion-label {
      margin: 0rem 1rem;
  }
  
  .bottomShareButtons ion-item:hover ion-label,
  .bottomShareButtons ion-item:hover ion-icon {
      color: var(--ion-color-primary);
  }
  
  .userCallInfo {
      display: none;
      position: fixed;
      bottom: 0;
      z-index: 10;
      width: 100%;
  
      ion-grid, ion-col {
          padding: 0rem;
          margin: auto 0rem;
  
          ion-icon, ion-label {
              color: var(--ion-color-light) !important;
              font-size: 10px;
          }
  
          ion-icon {
              margin-right: 3px;
              padding-bottom: 4px;
          }
  
      }
  }
  
  .user-info-mini {
      background-color: white;
      box-sizing: border-box;
      padding: 16px;
      width: 100%;
      height: 96px;
      box-shadow: rgba(0, 0, 0, 0.05) 0 -9px 28px 0;
      position: sticky;
      bottom: 0px;
      z-index: 1020;
      border-bottom: 1px solid rgb(234, 234, 234);
  
      picture {
          display: initial;
          height: 64px;
          width: 64px;
          flex: 0 0 auto;
          object-fit: cover;
          border-radius: 50%;
      }
  
      .wtsp-btn {
          picture {
              object-fit: contain;
              flex-shrink: 0;
              height: 30px;
              width: 30px;
              aspect-ratio: auto 30 / 30;
          }
      }
  
      .call-icon {
          font-size: 30px;
      }
  
      .user-detail {
          margin-left: 12px;
          margin-right: 12px;
      }
  
      ion-button {
          font-size: 0.875rem;
          font-weight: 600;
      }
  
  }

  
  @media (max-width: 767px) {

      .leftAdContainer {
          display: none !important;
      }
  
      .mpr-1 {
          padding-right: 1rem !important;
      }
  
      .mpl-1 {
          padding-left: 1rem !important;
      }
  
      .paddingBottomMedia {
          padding-bottom: 3rem !important;
      }
  
      .bottomAdContainer {
          margin-bottom: 4rem !important;
      }

      /*.detailScreen {
          .user-info{
              margin-bottom: 10rem;
          }
      }*/
      .userCallInfo {
          display: initial !important;
      }
      .ios {
          .userCallInfo {
              background-color: transparent;
  
              ion-icon {
                  margin-left: 3px;
              }
          }
      }
  }
  
  @media (max-width: 400px) {
      .user-info-mini {
          height: 72px;
  
          .user-info-avatar {
              display: none;
          }
  
          .wtsp-btn {
              picture {
                  object-fit: contain;
                  flex-shrink: 0;
                  height: 30px;
                  width: 30px;
                  aspect-ratio: auto 30 / 30;
              }
          }
  
          .user-detail {
              margin: 0;
  
              h3 {
                  font-size: 18px;
                  margin: 0;
              }
          }
  
      }
  
  }
  
  /*.marque {
      padding: 0px;
      margin: 0px;
      box-sizing: border-box;
      list-style: none;
      width: 100%;
      display: flex;
      flex-flow: column wrap;
  }
  
  .modele {
      box-sizing: border-box;
      height: 50px;
      width: 50%;
      display: flex;
      -webkit-box-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      align-items: center;
      padding: 0px 16px;
  }*/
  