html[dir="LTR"]{
  .navbar {
    .brand-center {
      display: flex;
      flex-direction: row;
      align-content: center;
      justify-content: center;

      a:first-child {
        border-right: 1px solid var(--ion-color-primary);
        margin-right: 1em;
        padding-right: 1em;
      }

      .headerCheckmark {
        display: flex;
        flex-direction: row;
        align-content: center;
        justify-content: center;
        color: var(--ion-color-primary) !important;
        margin: auto 0rem;
        font-size: 1.6rem;
        line-height: 1.9rem;
      }

      @media all and(max-width: 767px) {
        .headerCheckmark {
          display: none;
        }

        a:first-child {
          border: 0!important;
          margin: 0!important;
          padding: 0!important;
        }
      }
    }
  }
}
html[dir="RTL"]{
  .navbar {
    .brand-center {
      display: flex;
      flex-direction: row;
      align-content: center;
      justify-content: center;
      padding-left: 25px;

      a:first-child {
        border-left: 1px solid var(--ion-color-primary);
        margin-left: 1em;
        padding-left: 1em;
      }

      .headerCheckmark {
        display: flex;
        flex-direction: row;
        align-content: center;
        justify-content: center;
        color: var(--ion-color-primary) !important;
        margin: auto 0rem;
        font-size: 1.6rem;
        line-height: 1.9rem;
      }

      @media all and(max-width: 767px) {
        .headerCheckmark {
          display: none;
        }

        a:first-child {
          border: 0!important;
          margin: 0!important;
          padding: 0!important;
        }
        .ion-menu-toggle{
          margin-left: 0rem;
        }
      }
    }
  }
}

