.grid-popular-tags-container {
    background-color: var(--ion-color-light-tint);
    margin: 3rem 0rem;
    border-radius: 1.25rem;
    .popular-tags-list {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        ion-chip {
            --background: var(--ion-color-secondary-tint);
            font-weight: 500;

            a {
                color: var(--ion-color-dark);
            }
        }
        ion-chip:hover {
            --background: var(--ion-color-primary);
        }
    }
    
}


@media (max-width: 767px) {
 
    .grid-popular-tags-container {
        margin: 1rem 1rem;
    }
}
