.subsearch-container{
  padding: 0 12px;
}

ul.subsearch {
  list-style: none;
  padding: 0rem;
  margin-top: 1rem;
  h1 {
    font-size: 17px;
    text-align: start;
    display: flex;
    flex-wrap: wrap;

  }
}

html[dir="LTR"]{

  ul.subsearch li {
    display: inline-block;
    float: left;
    line-height: 2rem;
  }

  ul.subsearch li:first-child {
    //border-right: 1px solid var(--ion-color-medium);
  }
}

html[dir="RTL"]{

  ul.subsearch li {
    display: inline-block;
    float: right;
    line-height: 2rem;
  }

  ul.subsearch li:first-child {
    //border-left: 1px solid var(--ion-color-medium);
  }
}

ul.subsearch li ion-item {
  border: 0px;
}

ul.subsearch li ion-item ion-label {
  margin-left: 0;
}

@media (max-width: 768px) {
  .subsearch-container{
    padding: 0;
  }

  ul.subsearch {
    h1 {
      font-size: 13px;
  }
  }

  ul.subsearch li:first-child {
    border-right: 0px;
  }

  ul.subsearch li:first-child ion-label{
    margin-left: 0px;
  }
}
