ul.subsearch {
    list-style: none;
    padding: 0rem;
    margin-top: 1rem;
  }

  html[dir="LTR"]{
  
    ul.subsearch li {
      display: inline-block;
      float: left;
      line-height: 2rem;
    }
  
    ul.subsearch li:first-child {
      //border-right: 1px solid var(--ion-color-medium);
    }
  }
  
  html[dir="RTL"]{
  
    ul.subsearch li {
      display: inline-block;
      float: right;
      line-height: 2rem;
    }
  
    ul.subsearch li:first-child {
      //border-left: 1px solid var(--ion-color-medium);
    }
  }
  
  ul.subsearch li ion-item {
    border: 0px;
  }
  
  ul.subsearch li ion-item ion-label {
    margin-left: 1rem;
  }

  .category-select-container {
    .MuiFormControl-root {
      width: 100%;
    }
  }

  .ion-item-search-verification {
    border-radius: 1rem;
  }

  .MuiSelect-select.MuiSelect-select {
    display: flex;

  }
  
  @media (max-width: 768px) {
    ul.subsearch {
      text-align: center;
    }
  
    ul.subsearch li:first-child {
      border-right: 0px;
    }
  
    ul.subsearch li:first-child ion-label{
      margin-left: 0px;
    }
  }
  