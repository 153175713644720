.col-md-4.col-sm-12.d-sm-none {
  display: none !important;
  padding-top: 1rem;
}
.my-ion-content #background-content {
  --background: var(--ion-background-color, rgb(248, 248, 248));
}
/*.MuiBreadcrumbs-root{
  margin-right: 9rem !important;
  margin-left: 9rem !important;
}*/

.MuiBreadcrumbs-li{
   border-radius: .5rem;
   background-color: var(--ion-color-light-tint);
   color: var(--ion-color-primary);
   a {
    padding: .5rem;
    font-size: 13px;
   }
   a:hover {
    color: var(--ion-color-dark);
   }

}
.MuiBreadcrumbs-li:hover, .MuiBreadcrumbs-li:focus {
  box-shadow: 0px 0px 5px 0px var(--ion-color-medium-tint);
}

.MuiBreadcrumbs-separator {
  font-size: 20px;
}

.search-modal-class {
  .modal-wrapper {
    .ion-page {
      justify-content: flex-start;
    } 
  }
}

.serach-button-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 3px;
  background-repeat: no-repeat;
  background-size: cover;

  ion-button {
    width: 20rem;
    color: var(--ion-color-primary);
    background-color: var(--ion-color-light);
    border-radius: 1rem;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07),
    0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07),
    0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);
    --border-radius: 1rem;
    p {
      width: 100%;
      margin-bottom: 0;
    }
  }
}

.hide-button-container {
  display: flex;
  justify-content: center;
  ion-button {
    height: 1rem;
  }
}

.fab-button {
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .col-md-4.col-sm-12.d-sm-none {
    display: block !important;
  }
}
@media (max-width: 768px) {
  .MuiTypography-root{
    margin-right: .2rem !important;
    margin-left: .2rem !important;
  }
  /*.MuiBreadcrumbs-separator {
    margin-left: 2px;
    margin-right: 2px;
  }*/
}

