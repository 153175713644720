@font-face {
  font-family: 'DINNextLTW23';
  src: url('fonts/din-next-lt-w23-regular.ttf');
  font-weight: normal;
  font-style: normal;
}

html.ios {
  --ion-default-font: DINNextLTW23, -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Roboto", sans-serif;
}

html.md {
  --ion-default-font: DINNextLTW23, "Roboto", "Helvetica Neue", sans-serif;
}

html {
  --ion-font-family: var(--ion-default-font);
}

html[dir="RTL"] body {
  text-align: right !important;
}

body {
  font-family: var(--ion-font-family);
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
}

html[dir="RTL"] .ml-auto {
  margin-right: auto !important;
  margin-left: initial !important;
}

html[dir="RTL"] .mr-auto {
  margin-right: initial !important;
  margin-left: auto !important;
}


ion-toggle {
  --background: #eaebff;
  --background-checked: var(--ion-color-primary-tint);
}

div.search .announcement {
  color: #113218 !important;
}

ion-app {
  margin-top: env(safe-area-inset-top);
  margin-bottom: env(safe-area-inset-bottom);
}

.loading-custom-class {
  --spinner-color: var(--ion-color-primary);
}

.CookieConsent {
  max-height: 50px;
  font-size: 12px;
  display: flex;
  align-content: center;

  .cookie-container {
    display: flex;
    align-items: center;

    a:hover {
      color: var(--ion-color-primary);
      text-decoration: underline;
    }
  }

  img {
    margin: 10px 10px 10px 10px;
  }
}

.PhoneInput {
  width: 100%;

  .PhoneInputCountrySelect {
    background: var(--background);
  }

  .PhoneInputInput {
    background: var(--background);
    border-color: #dcdcdc;
    border-width: thin;
    border-style: solid;
    border-radius: 6px;
    margin-bottom: 4px;
    font-size: 13px;
    height: 32px;
    padding: var(--padding-top) var(--padding-end) var(--padding-bottom) var(--padding-start);
  }
}

.PhoneInput--focus {
  .PhoneInputInput {
    border-color: #dcdcdc;
    border-width: thin;
    border-style: solid;
    outline: 0;
  }
}

html[dir="RTL"] {
  .PhoneInput {
    .PhoneInputCountrySelect {
    }

    .PhoneInputCountrySelectArrow {
      margin-right: var(--PhoneInputCountrySelectArrow-marginLeft);
    }

    .PhoneInputInput {
      direction: ltr;
      text-align: right;
      padding: var(--padding-top) var(--padding-start) var(--padding-bottom) var(--padding-end);
    }
  }

  .ql-editor {
    text-align: right !important;
  }


  .MuiStepLabel-iconContainer {
    padding-right: unset !important;
    padding-left: 8px !important;
  }
}

@media (max-width: 768px) {

  .CookieConsent {
    max-height: 200px;

  }
}

.p-sticky-top {
  @supports (position: sticky) {
    position: sticky;
    top: 1%;
  }
}

ion-card {
  border-radius: 6px;
  box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.07);
  background-color: #fff;
}

@media only screen and (min-width: 768px) and (min-height: 600px) {
  .sc-ion-modal-md-h {
    --width: 750px;
    --height: 555px;
  }
}

@media only screen and (min-width: 768px) and (min-height: 768px) {
  .sc-ion-modal-md-h {
    --width: 1000px;
    --height: 820px;
  }
  .overflowScroll{
    height: 615px;
    overflow-y: scroll;
  }
}

.dir-ltr {
  direction: ltr !important;
}

.MuiList-padding {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

ion-list {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

@media (min-width: 1400px) {
  .container {
    max-width: 1280px;
  }
  .overflowScroll{
    max-height: 615px;
    overflow-y: scroll;
  }
}

@media (min-width: 1070px) {
  .overflowScroll{
    max-height: 615px;
    overflow-y: scroll;
  }
}

.MuiStepIcon-root.MuiStepIcon-active, .MuiStepIcon-root.MuiStepIcon-completed {
  color: var(--ion-color-primary) !important;
}

.MuiStepper-root {
  padding: 24px 0 16px 0!important;
}
.input-radius{
    border-radius: 30px;
}
.adTitle1{
    font-weight: bold;
    color: black;
}