div.search form ion-grid {
    background: var(--ion-color-light-tint);
    border-radius: .5rem;
    box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.07);
    ion-col ion-item ion-select {
        padding-bottom: 0;
    }
}

.MuiTypography-root {
    font-family: var(--ion-font-family) !important;
}

.search {
    ion-spinner {
        max-height: 17px;
    }

    .item-has-focus .label-floating {
        font-size: 18px;
        font-weight: bold;
        color: var(--ion-color-primary);
        margin-top: -10px;
    }

    .item-has-value .label-floating {
        font-size: 20px;
        margin-top: -15px;
    }

    .label-floating {
        margin-top: -10px;
    }

    .item-query {
        margin-top: 0px;
        padding-left: 6px;
        padding-right: 6px;
        border-radius: 2rem;
    }

    .category-region-container {
        display: flex;
    }

    .category-select-container {
        .MuiFormControl-root {
            width: 100%;
        }

        .MuiSelect-root {
            background-color: white;
            border-radius: 1rem;
        }
    }

    .region-select-container {
        .MuiFormControl-root {
            width: 100%;
        }

        .MuiSelect-root {
            background-color: white;
            border-radius: 1rem;
        }

    }

    .MuiFormLabel-root {
        color: var(--ion-color-medium);
        font-size: 20px;
        font-weight: bold;
    }

    :hover .MuiFormLabel-root {
        color: var(--ion-color-primary);
    }

    .MuiFormLabel-root.Mui-focused {
        color: var(--ion-color-primary);
    }

    .MuiSelect-select {
        display: flex;
        padding-top: 20px;

    }

    .MuiInputLabel-shrink {
        margin-top: 3px;
    }

    .MuiSelect-outlined {
        padding: 14px 9px 4px 20px;
    }

    .MuiListItemText-root {
        align-self: flex-start;
        flex: inherit;
    }
}
ion-list {
    ion-list-header {
        ion-label{
            font-size: 1.5rem;
        }
    }
}

@media all and(max-width: 767px) {
    .search {

        .category-select-container {
            .MuiSelect-root {
                padding-bottom: 2px;
                padding-top: 12px;
            }
        }

        .md {

            .item-has-focus .label-floating {
                font-size: 22px;
            }

            .item-has-value .label-floating {
                font-size: 22px;
                margin-top: -10px;
            }
        }

        .ios {

            .item-has-focus .label-floating {
                font-size: 18px;
                margin-top: 8px;
            }

            .item-has-value .label-floating {
                font-size: 20px;
                margin-top: 0px;
            }

            .item-input {
                padding: 0px 10px
            }

        }
    }
}
