.upload-keyword-class .modal-wrapper {
  display: flex;
  ion-header {
    background-color: var(--ion-color-primary);
    min-height: 3rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  ion-button {
    margin-left: 5rem;
    margin-right: 5rem;
    --border-radius: .7rem;
    span {
      width: 100%;
    }
  }
}

.links {
  width: 100%;
    margin: 1rem 14rem 0rem 14rem;
    background-color: #ffffff;
    ion-row {
        background: var(--ion-color-light);
        box-shadow: 0 0 1px -2px var(--ion-color-dark);
        padding: 8px 10px;
      }

    ion-row ion-col ion-button{
      height: 100%;
      display: block;
      width: 100%;
    }
    
    ion-row ion-col ion-button ion-icon{
      margin-right: 5px;
    }

    ion-row ion-col ion-button span{
      width: 100%;
    }
}
.Profile {
    width: 100%;
    margin: 0rem 16rem 1rem 16rem;
    border-radius: .5rem;
    box-shadow: 0rem 0rem 0.2rem -0.1rem var(--ion-color-dark);
    background-color: #ffffff;
    ion-row {
        background: var(--ion-color-light);
        box-shadow: 0 0 1px -2px var(--ion-color-dark);
      }

    ion-row ion-col ion-button{
      height: 100%;
      display:flex;
      
    }
    
    ion-row ion-col ion-button ion-icon{
      margin-right: 5px;
    }
      .card-title{
        padding: 0 .5rem;
      }
  
      span.right {
        float: right;
        display: flex;
        flex-direction: column;
  
        ion-icon {
          font-size: 2rem;
          color: var(--ion-color-medium);
          align-self: flex-end;
          margin-top: .3rem;
        }
      }

      .alert.alert-danger{
        display: flex;
        flex-direction: row;
        line-height: 2rem;
        margin-top: 1rem;
        align-items: center;
    
        ion-icon{
          margin: 1rem 1rem 1rem 1rem;
        }
      }

      .btn {
        display: flex;
        font-size: 15px;
        justify-content: center;
        ion-icon {
          padding: 0 7px;
        }
      }
    
    }


@media all and(max-width: 767px) {
  .Profile {
    margin: 0.3rem 1rem 1rem 1rem;
    .alert.alert-danger{
      opacity: 100;
      font-size: 14px;
      
    }
  }
  .links {
    margin: 1rem 0rem 0rem 0rem;
    ion-row {
      padding: inherit;
    }
  }
}