header.top-header {
  background-color: #24252a;

  .nav-links a {
    font-weight: 500;
    color: #edf0f1 !important;
    text-decoration: none;
  }

  .nav-links {
    list-style: none;

    li {
      padding: 0px 20px;
    }

    li a {
      transition: color 0.3s ease 0s;
    }

    li a:hover {
      color: var(--ion-color-primary) !important;
    }

  }
}