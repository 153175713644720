.registerPage{
  --background: #f8f8f8;

}

.ios{
  .ion-label{
    overflow: visible;
    font-size: 16px;
  }
}

.registerForm {
  margin: 1rem 20rem;
  border-radius: .5rem;
  box-shadow: 0rem 0rem .2rem -.1rem var(--ion-color-dark);
  background-color: #ffffff;

  span.right{
    float: right;
    display: flex;
    flex-direction: column;

    ion-icon{
      font-size: 2rem;
      color: var(--ion-color-medium);
      align-self: flex-end;
      margin-top: .3rem;
    }
  }


  ion-text{
    font-size: .5rem;
    margin : 0rem;
  }

  
}

.toast-success{
  --color: #155724;
  --background: #d4edda;
  --border-radius: 1rem;
  --box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07),
  0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07),
  0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);
  margin-top: 9rem;
  
} 

.success-alert{
  .alert-wrapper {
    border-radius: 1rem;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07),
    0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07),
    0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);
    display: flex;

  }
  .alert-message {
    text-align: center;
    max-height: unset;
  }
  .alert-button-group {
    display: flex;
    flex-direction: column;
    align-content: center;
  }
  
}

footer {
  margin-top: 0rem !important;
}

@media all and(max-width: 1200px) {
  .registerForm {
    margin: 1rem 10rem;
  }
}

@media all and(max-width: 991px) {
  .registerForm {
    margin: 1rem 5rem;
  }
}

@media all and(max-width: 767px) {
  .registerForm {
    margin: 1rem 0rem;
  }
}

@media (max-width: 767px) {
  .registerForm {

    ion-text{
      margin-bottom: .3rem;
    }

    ion-item ion-label{
      font-size: 18px;
    }
     .native-input.sc-ion-input-ios{
       font-size: 14px;
       padding-right: 0rem;
       padding-left: 0rem;
    }
    .native-input.sc-ion-input-md{
      font-size: 15px;
      padding-right: 0rem;
    }
  }
}

