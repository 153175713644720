.grid-categories-seo-container{
    display: flex;
    /*margin: 2rem 0 0;*/
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;

    .principal-category-box {
        display: flex;
        flex-direction: column;
        margin: .5rem;

        h6 {
            color: var(--ion-color-primary);
        }

        .sub-category-box {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            font-size: 13px;

            ion-text {
                color: var(--ion-color-dark);
            }
        }
    }
}

@media (max-width: 768px) {
    .grid-categories-seo-container{
        display: none;
    }

}
