ion-menu{
  z-index: 209999;
  background-color: rgba(0, 0, 0, 0.53);
  .cursors > div:hover {
    background: #eee;
  }
}

ion-menu ion-header img {
  height: 4rem;
  display: block;
  margin: 5px auto;
}

ion-menu ion-list ion-button {
  display: block;
  width: 100%;
  height: 3rem;
  font-size: 14px;
  --background-hover: var(--ion-color-primary) !important;
}

ion-menu ion-list ion-item:nth-child(1), ion-menu ion-list ion-item:nth-child(2) {
  margin-top: 1rem;
}

ion-menu ion-list ion-item a, ion-menu ion-list ion-item ion-router-link {
  display: block;
  width: 100%;
}
ion-menu ion-list div ion-item  {
  display: block;
  width: 100%;
  margin-top: auto !important;
}
ion-menu ion-list ion-item a ion-text, ion-menu ion-list ion-item ion-router-link ion-text {
  display: flex;
  flex-direction: row;
}
ion-menu ion-list div ion-item ion-text {
  display: flex;
  flex-direction: row;
}

ion-menu ion-list ion-item a ion-text ion-icon, ion-menu ion-list ion-item ion-router-link ion-text ion-icon {
  color: var(--ion-color-primary);
  max-width: 30px;
  max-height: 30px;
  margin: 0 18px;
}

ion-menu ion-list ion-item  ion-button ion-icon {
  border-width: thin;
  border-color: white;
  border-style: solid;
  margin-right: 6px;
  margin-left: 6px;
  border-radius: 4px;
}

ion-menu ion-list ion-item a ion-text span, ion-menu ion-list ion-item ion-router-link ion-text span {
  flex: 3;
  color: var(--ion-color-dark);
  text-decoration: none;
  padding-top: 5px;
}

ion-menu ion-list div ion-item ion-text span {
  flex: 3;
  color: var(--ion-color-dark);
  text-decoration: none;
}
ion-menu ion-list div ion-item ion-text {
  margin-left: 18px;
}

ion-menu ion-list ion-item a:hover, ion-menu ion-list ion-item ion-router-link:hover {
  text-decoration: none !important;
}

ion-menu ion-list ion-item a:hover ion-text span, ion-menu ion-list ion-item ion-router-link:hover ion-text span {
  color: var(--ion-color-primary);
}

ion-menu ion-list ion-item a:hover ion-text ion-icon, ion-menu ion-list ion-item ion-router-link:hover ion-text ion-icon {
  color: var(--ion-color-primary);
}

ion-menu ion-list div ion-item ion-text ion-icon {
  color: var(--ion-color-primary);
  margin-right: 1rem;
  max-width: 30px;
  max-height: 30px;
}

ion-menu ion-list ion-item svg {
  height: 1.5rem;
  margin-right: .5rem;
}

ion-menu ion-list div ion-item svg {
  height: 1.5rem;
  margin-right: .5rem;
}

.social-container{
  text-align: center;
  align-content: center;
  ion-item  {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    height: 38px;
    ion-icon { 
      padding: 0px 3px;
  
    }
  }

}
.sign-out {
  margin: 3%;
  height: 30px;
  width: 95%;
  color: black;
  background: #bbc3ca91;
  border-radius: 4px;
}
.version-text{
  font-size: 12px;
  
}

@media all and(max-width: 767px) {
  .svgClass {
    padding: 6px;
    margin-left: 12px;
    margin-right: 13px;
  }

  .ios{
    .social-container{
      ion-item  {
        width: 59%;
      }
    }
  }
}
