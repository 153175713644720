.banner-container {
  margin: 0;
  padding: 0;
  border: 0;
  background-color: transparent;
  color: black;
  font: inherit;
  vertical-align: baseline;
  line-height: 1em;
  box-shadow: 0px 0px 8px -3px var(--ion-color-dark);
  width: 100%;


  .close-button-container {
    display: flex;
    align-items: center;
    padding: 0;
    margin-left: -7px;
    width: 40px;

  }

  .icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .text-container {
    margin: 0 8px;
    padding: 0px 0px 11px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 14px;
    text-align: center;
  }

  .open-button-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;


    ion-button {
      width: 80px !important;
    }
  }
}