div.search{
  background-position: top center;
  background-size: unset;
  background-repeat: no-repeat;
  background-attachment: scroll;
  padding: 1rem 2rem;
}

div.search .announcement{
  text-align: center;
  color: var(--ion-color-light);
  padding: 2rem;
  h1 {
    font-weight: 500;
    font-size: 16px;
    line-height: 0;
    margin: 0;
  }

  h2 {
    font-weight: 500;
    font-size: 16px;
    line-height: 0;
    margin: 0;
  }
}

@media (max-width: 991.98px) {
  div.search{
    background-position: top left;
    background-size: initial;
    min-height: 15rem;
  }
}

@media (max-width: 767px) {
  div.search {
    padding: 0rem 0rem;
  }
}
