.post-item-container {
  position: relative;

  .card-top-options {
    position: absolute;
    right: 1.5%;
    top: 1.5%;
  }
  .card-top-options-2 {
    position: absolute;
    right: 5%;
    top: 1.5%;
  }
  .my-ion-star {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    position: absolute;
    top: 0;
    height: 23px;
    right: 0;
    margin-right: 28px;
    margin-top: 7px;
    z-index: 999;
  }
  .post-item {
    img {
      width: 150px;
      height: 150px;
      object-fit: cover;
    }

    .annonce-star-bg{
      background-color: rgb(254, 246, 233);
    }

    .media-body {
      height: 100%;
    }

    ion-card-header {
      ion-card-title {
        font-weight: 700;
      }

      ion-card-subtitle {
        font-weight: 700;

        span {
          color: #333;
          font-size: 16px;
        }
      }
    }
  }

  .body-bottom {
    padding-inline: 16px;
  }

  .my-ion-badge {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    height: 23px;
    right: 0;
    margin-right: 18px;
    margin-bottom: 7px;
  }

  /*.boost-info {
    position: absolute;
    bottom: 0;
    height: 12px;
    font-size: 12px;
    right: 0;
    margin-right: 6px;
    margin-bottom: 35px;
  }*/
}

html[dir="RTL"] .post-item-container {
  .card-top-options {
    right: unset !important;
    left: 1.5% !important;
  }
  .my-ion-star {
    left: 0 !important;
    right: unset !important;
    margin-right: unset !important;
    margin-left: 28px;
  }
  .post-item {

    .my-ion-badge {
      left: 0 !important;
      right: unset !important;
      margin-right: unset !important;
      margin-left: 18px;
    }
  }
}

@media (max-width: 991px) {

}

@media (max-width: 480px) {
  .post-item-container {

    .my-ion-star {
      height: 20px;
      margin-right: 13px;
      margin-top: 3px;
    }

    .post-item {
      img {
        width: 116px;
        height: 116px;
        object-fit: cover;
      }

      ion-card-header {
        padding: 6px 0 4px 4px;

        ion-card-title {
          font-size: 16px;
          line-height: 1.2;
        }

        ion-card-subtitle {
          margin-top: 0;
          text-transform: inherit;

          span {

            font-size: 14px;
          }
        }
      }

      .body-bottom {
        padding-inline: 4px;
        font-size: 10px;

        .body-bottom-category {
          font-size: 12px;
        }
      }

      .my-ion-badge {
        height: 20px;
        margin-right: 3px;
        margin-bottom: 3px;
      }
    }
  }
  html[dir="RTL"] .post-item-container {
    .my-ion-star {
      left: 0 !important;
      right: unset !important;
      margin-right: unset !important;
      margin-left: 13px;
    }
    .post-item {
      ion-card-header {
        padding: 6px 4px 4px 0;
      }



      .my-ion-badge {
        left: 0 !important;
        right: unset !important;
        margin-right: unset !important;
        margin-left: 3px;
      }
    }
  }
}