ion-card .carousel:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-animation: _2cq0i 2s infinite;
    animation: _2cq0i 2s infinite;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(0, hsla(0, 0%, 100%, 0)), color-stop(20%, hsla(0, 0%, 100%, .2)), color-stop(60%, hsla(0, 0%, 100%, .5)), to(hsla(0, 0%, 100%, 0)));
    background-image: -webkit-linear-gradient(left, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .2) 20%, hsla(0, 0%, 100%, .5) 60%, hsla(0, 0%, 100%, 0));
    background-image: -o-linear-gradient(left, hsla(0, 0%, 100%, 0) 0, hsla(0, 0%, 100%, .2) 20%, hsla(0, 0%, 100%, .5) 60%, hsla(0, 0%, 100%, 0));
    background-image: -webkit-gradient(linear, left top, right top, from(hsla(0, 0%, 100%, 0)), color-stop(20%, hsla(0, 0%, 100%, .2)), color-stop(60%, hsla(0, 0%, 100%, .5)), to(hsla(0, 0%, 100%, 0)));
    background-image: -o-linear-gradient(left, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .2) 20%, hsla(0, 0%, 100%, .5) 60%, hsla(0, 0%, 100%, 0));
    background-image: linear-gradient(90deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .2) 20%, hsla(0, 0%, 100%, .5) 60%, hsla(0, 0%, 100%, 0));
}

@-webkit-keyframes carouselPlaceholder {
    to {
        -webkit-transform: translateX(100%);
        transform: translateX(100%)
    }
}

ion-card .carousel, ion-card .carousel .owl-carousel,
ion-card .carousel .owl-carousel .owl-stage-outer,
ion-card .carousel .owl-carousel .owl-stage-outer .owl-stage,
ion-card .carousel .owl-carousel .owl-stage-outer .owl-stage .owl-item,
ion-card .carousel .owl-carousel .owl-stage-outer .owl-stage .owl-item img,
ion-card .image-gallery {
    max-height: 400px;
}

ion-card .carousel .owl-carousel .owl-stage-outer .owl-stage .owl-item img {
    height: 100%;
}

ion-card .carouselNav {
    margin: 2rem .5rem;
    position: absolute;
    bottom: 1rem;
    z-index: 10;
}

ion-card .carouselNav span {
    margin: 0rem .5rem;
    border-radius: .5rem;
    display: inline-block;
    border: 1px solid var(--ion-color-dark);
    width: 5rem;
    height: 5rem;
}

ion-card .carouselNav span.active {
    background-color: var(--ion-color-light);
}

ion-card .carouselNav span img {
    border-radius: .5rem;
    height: 100%;
}

ion-card .carouselNav span.active img {
    opacity: .5;
}

ion-card .prev, ion-card .next {
    margin: 2rem .5rem;
    position: absolute;
    top: 35%;
    z-index: 10;
}

ion-card .prev {
    left: 1rem !important;
}

ion-card .next {
    right: 1rem !important;
}

@media (max-width: 991px) {
    ion-card .carouselNav {
        display: none;
    }
}

ion-card .image-gallery-slides {
    max-height: 400px;
}

ion-card .image-gallery-image {
    height: 400px;
}

.image-gallery-slide .image-gallery-image {
    background-color: rgb(236, 236, 236);
}
.image-gallery-bullets .image-gallery-bullet {
    background: rgb(208, 208, 208)!important;
    border: 1px solid rgb(208, 208, 208)!important;
    opacity: 0.6;
}
.image-gallery-bullets .image-gallery-bullet.active {
    background: var(--ion-color-primary-tint)!important;
    border: 1px solid var(--ion-color-primary-tint)!important;
    opacity: 1;
}
@media (min-width: 768px) {
    .image-gallery-icon:hover {
        color: var(--ion-color-primary-tint)!important;
    }
}
